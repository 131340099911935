.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  top: -15px;
  width: 100%;
  text-align: left;
  font-family: Poppins;
}

.geosuggest__input {
  width: 100%;
  border-bottom: 1px solid #c5c3c3;
  box-shadow: 0 0 0px #3d464d;
  padding: 0.5em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  font-family: Poppins;
  font-size: 14px;
}

.geosuggest__input:focus {
  border-color: transparent;
  border-bottom-color: #8964fd;
  box-shadow: 0 0 0 transparent;
}

.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
