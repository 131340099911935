:root {
  --white: #ffffff;
  --black: #323233;
  --dark-violet: #6939f7;
  --darker-violet: #17278d;
  --violet: #825af8;
  --light-violet: #e6dcfa;
  --lighter-violet: #f9f7fd;
  --blue: #6564fd;
  --gray: #575557;
  --normal-gray: #c5c3c3;
  --medium-gray: #f0f0f0;
  --light-gray: #fdfbfb;
  --dark-red: #ed052a;
  --red: #fb4c68;
  --green: #69bb7b;
  --hype: #453f6b;
  --yellow: #ffa800;
  --gradient: linear-gradient(151deg, #fc5c72, #e35e90 25%, #6564fd);
  --gradient-violet: linear-gradient(112deg, #a362dd, #8964fd);
  --gradient-yellow: linear-gradient(274deg, #ffa800, #f58d06);
  --gradient-gray: linear-gradient(117deg, #e6e4e4, #cdcdcd);
  --transparent: transparent; // --intensity: 4px;
  --shadow: rgba(0, 0, 0, 0.1);
  --app-height: 100%;
}

*:not(input, select, textarea, button) {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.tdu {
  text-decoration: underline;
}

.wwbw {
  word-wrap: break-word;
  display: inline-block;
}

.ChatModule * {
  user-select: text !important; /* supported by Chrome and Opera */
  -webkit-user-select: text !important; /* Safari */
  -khtml-user-select: text !important; /* Konqueror HTML */
  -moz-user-select: text !important; /* Firefox */
  -ms-user-select: text !important; /* Internet Explorer/Edge */
}

.ttu {
  text-transform: uppercase;
}
.zi1200 {
  z-index: 1200;
}
.zi999999 {
  z-index: 999999;
}

.zi900 {
  z-index: 900;
}

@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import 'node_modules/react-geosuggest/module/geosuggest';
@import 'src/shared/forms/GeoSuggestForm/GeoSuggestForm.scss';

body {
  #root > div {
    width: 100%;
  }

  max-width: 100vw;
  overflow-x: hidden;
  /*
  * this will fix the full height bug in ios 15 safari
  * which has bottom tab bar.
  * --app-height is set in index.js by js function
  */
  min-height: 100vh;
}

html {
  background-color: var(--lighter-violet);
  @media (max-width: 1023.9px) {
    background-color: var(--white);
  }
}

.dario-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}
.bg-chat {
  background-color: #f9f7fd;
}
.ofxh {
  overflow-x: hidden;
}
.btw1 {
  border-top-width: 1px;
  border-top-style: solid;
}
.dark-gray {
  color: #a1a4a7;
}
.yellow {
  color: var(--yellow);
}
.fw500 {
  font-weight: 500;
}
.ph-custom-gray::placeholder {
  color: var(--gray);
  font-weight: 500;
  letter-spacing: 0.5px;
}
.tdlt {
  -webkit-text-decoration-line: line-through; /* Safari */
  text-decoration-line: line-through;
}
.credit-card {
  max-width: 350px !important;
  background: linear-gradient(
    215.92deg,
    #fa5d75 7.33%,
    rgba(210, 95, 157, 0.9) 39.56%,
    rgba(120, 100, 246, 0.67) 78.46%
  );
  box-shadow: 0px 4px 8px rgba(121, 75, 255, 0.32);
}
.fw500 {
  font-weight: 500;
}
.t4 {
  transition: 4s !important;
}
.f1 {
  flex: 1;
}
@media (max-width: 1023.9px) {
  .sm-stp {
    position: static !important;
  }
  .sm-br4 {
    border-radius: calc(var(--intensity) * 4) !important;
  }
  .sm-pt0 {
    padding-top: 0 !important;
  }
  .sm-pt5 {
    padding-top: calc(var(--intensity) * 5) !important;
  }
  .sm-pt12 {
    padding-top: calc(var(--intensity) * 12) !important;
  }
  .sm-pt16 {
    padding-top: calc(var(--intensity) * 16) !important;
  }
  .sm-mt-12 {
    margin-top: calc(var(--intensity) * -12) !important;
  }
  .sm-special--header {
    border-radius: 0 0 24px 24px !important;
    box-shadow: inset 0px -1px 0px var(--light-violet) !important;
  }
  .sm-h100m {
    height: calc(100vh - 64px) !important;
  }
  .sm-ofys {
    overflow-y: scroll;
  }
  .sm-fpb50 {
    padding-bottom: 50px;
  }
  .sm-dn {
    display: none !important;
  }
}
@media (min-width: 1024px) {
  .lg-bw2 {
    border-width: 2px;
    border-style: solid;
  }
  .lg-mnhp70 {
    min-height: 70%;
  }
  .lg-h-centered {
    top: 50%;
    transform: translateY(-50%);
  }
  .lg-fp {
    position: fixed;
  }
  .lg-bg-lighter-violet {
    background-color: var(--lighter-violet) !important;
  }
  .lg-b-medium-gray {
    border-color: var(--medium-gray);
  }
  .lg-bg-white {
    background-color: var(--white) !important;
  }
  .lg-stp {
    position: static;
  }
  .lg-s10 {
    box-shadow: 0 0 10px 0 var(--shadow);
  }
  .lg-h50 {
    height: 50px;
  }
  .lg-p4 {
    padding: calc(var(--intensity) * 4) !important;
  }
  .lg-mt4 {
    margin-top: calc(var(--intensity) * 4) !important;
  }
  .lg-br1 {
    border-radius: calc(var(--intensity) * 1);
  }
  .mnhd100wh {
    min-height: calc(100vh - 80px);
  }
  .hd100wh {
    height: calc(100vh - 80px);
  }
  .mxhd100wh {
    max-height: calc(100vh - 80px);
  }
}
.accordion-item {
  padding: 0 calc(var(--intensity) * 5);
  transition: height 0.7s ease;
  height: 0;
  width: 100%;
  overflow: hidden;
  opacity: 0;
}
.accordion-wrapper {
  width: 100%;
  .accordion-item {
    height: auto;
    padding: calc(var(--intensity) * 5);
    opacity: 1;
    border-color: var(--light-violet);
    border-top-width: 2px;
    border-top-style: solid;
  }
}
.special--header {
  border-radius: 0 0 24px 24px !important;
  box-shadow: inset 0px -1px 0px var(--light-violet) !important;
}
.ma {
  margin: 0 auto;
}
.mnhp70 {
  min-height: 70%;
}
.tr90 {
  transform: rotate(90deg);
}
.trm90 {
  transform: rotate(-90deg);
}
.ph-o100::placeholder {
  opacity: 1;
}

.br0 {
  border-radius: 0;
}

.fw500 {
  font-weight: 500;
}
.bg-custom-violet {
  background-color: #f9f7fd;
}
.h-centered {
  top: 50%;
  transform: translateY(-50%);
}
.pt8 {
  padding-top: calc(var(--intensity) * 8);
}
.pt12 {
  padding-top: calc(var(--intensity) * 12);
}
.mt-12 {
  margin-top: calc(var(--intensity) * -12);
}
.pl3 {
  padding-left: calc(var(--intensity) * 3);
}
.tr180 {
  transform: rotate(180deg);
}
.ttc {
  text-transform: capitalize;
}
.ofyh {
  overflow-y: hidden;
}
.mb70 {
  margin-bottom: 70px;
}
.tt3d {
  transform: translate3d(0, 0, 0) !important;
}
.old-app,
.__floater__body,
#tippy-2,
.old-tooltip {
  * {
    outline: none !important;
    box-sizing: border-box;
    font-family: 'Poppins', serif;
    scrollbar-width: none;
    -webkit-tap-highlight-color: transparent;
    transition: all 0.2s linear, transform 0s linear, left 0s linear,
      top 0s linear, bottom 0s linear, right 0s linear,
      background-color 0s linear;
  }
  .fw500 {
    font-weight: 500;
  }
  textarea {
    padding: 0;
    resize: vertical;
  }

  title {
    display: inline;
    color: var(--black);
    font-size: 22px;
    font-weight: 600;
    line-height: 27.5px;
    text-align: center;
    word-break: break-word;
    white-space: pre-line;
  }

  section {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
  }

  article {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }

  input:not([class*='Mui']) {
    border-style: none;
  }

  i:not([class*='Mui']) {
    line-height: unset !important;
  }

  .touchable {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &.enabled {
      &.bg-violet:hover {
        background-color: var(--dark-violet);
      }

      &.bg-red:hover {
        background-color: var(--dark-red);
      }

      &.bg-white:hover {
        background-color: var(--medium-gray);
      }

      &:hover .violet {
        color: var(--dark-violet);
      }

      &:hover .red {
        color: var(--dark-red);
      }
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.zoom:hover {
      transform: scale(1.05);
      box-shadow: 0 0 10px 0 var(--shadow);
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  .Spinner,
  .Pulse {
    animation: zoom 1s infinite linear;
  }

  input[type='file'] {
    opacity: 0;
    pointer-events: none;
    max-width: 0;
  }

  label {
    display: flex;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  input.white:-webkit-autofill {
    &,
    &:hover,
    &:focus,
    &:active {
      animation: autofill 0s forwards;
      -webkit-text-fill-color: var(--white) !important;
    }
  }

  input.black:-webkit-autofill {
    &,
    &:hover,
    &:focus,
    &:active {
      animation: autofill 0s forwards;
      -webkit-text-fill-color: var(--black) !important;
    }
  }

  @keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
    }
  }

  .Container,
  .ContainerShort {
    max-width: 1100px;
  }

  .DevTools div {
    flex-direction: row !important;

    button {
      min-height: 26px;
    }
  }

  div[class^='flatpickr-'] {
    div {
      flex-direction: row !important;
    }

    span {
      white-space: normal;
    }

    flex-direction: row !important;
  }

  div[role='button'] {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .mnhd100wh {
    min-height: calc(100vh - 64px);
  }

  .hd100wh {
    height: calc(100vh - 64px);
  }

  .mxhd100wh {
    max-height: calc(100vh - 64px);
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.5);
    }

    100% {
      transform: scale(1);
    }
  }

  .flgs {
    filter: grayscale(100%);
  }

  .zin1 {
    z-index: -1;
  }

  .tippy-tooltip.light-theme {
    padding: 0 !important;

    .tippy-content {
      border-radius: var(--intensity);
      overflow: hidden;
    }
  }

  .tippy-popper[x-placement^='top'] .tippy-arrow {
    border-top-color: white;
  }

  .tippy-popper[x-placement^='bottom'] .tippy-arrow {
    border-bottom-color: white;
  }

  .tippy-popper[x-placement^='left'] .tippy-arrow {
    border-left-color: white;
  }

  .tippy-popper[x-placement^='right'] .tippy-arrow {
    border-right-color: white;
  }

  .geosuggest__input-wrapper {
    width: 100%;
  }

  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    background: #fff;
    border-color: white;
    overflow: hidden;
    list-style: none;
    z-index: 5;
    box-shadow: 0 0 10px 0 var(--shadow);
    border-radius: 0 0 var(--intensity) var(--intensity);
  }

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  /**
   * A geosuggest item
   */
  .geosuggest__item {
    font-size: 18px;
    font-size: 1rem;
    padding: calc(var(--intensity) * 4);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .geosuggest__item:hover,
  .geosuggest__item:focus {
    background: #f5f5f5;
  }

  .geosuggest__item--active {
    background: #267dc0;
    color: #fff;
  }

  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    background: #ccc;
  }

  .geosuggest__item__matched-text {
    font-weight: bold;
  }

  .push-tutorial-animation canvas {
    filter: drop-shadow(0 5px 5px lightgray);
    transform: scale(1.25) translateX(-7.5%) translateY(-10%);
  }

  .grecaptcha-badge {
    bottom: 100px !important;
  }

  .__PrivateStripeElement {
    width: 100%;
  }

  .wbka {
    word-break: keep-all;
  }

  .Header {
    height: calc(64px + env(safe-area-inset-top));
    padding-top: env(safe-area-inset-top);
    transform: translate3d(0, 0, 0) !important;
  }

  .Header > * {
    transform: translate3d(0, 0, 0) !important;
  }

  .HeaderPlaceholderPadding {
    padding-top: calc(env(safe-area-inset-top));
  }

  .HeaderPlaceholderTop {
    position: absolute;
    top: calc(64px + env(safe-area-inset-top));
  }

  .BottomTab {
    height: calc(50px + env(safe-area-inset-bottom));
    transform: translate3d(0, 0, 0);
  }

  #uvp .powered {
    display: none;
  }

  .MuiAlert-message {
    width: 100%;
  }

  @import './dist/solid.min';
  .dark-violet {
    color: var(--dark-violet);
  }
  .bg-lighter-violet {
    background: var(--lighter-violet);
  }

  .lighter-gray {
    color: #9c9fa3;
  }
}

.waba {
  white-space: break-spaces;
}

p {
  font-family: 'Poppins';
}

pwa-camera-modal-instance {
  z-index: 1200;
}

.pt-esait {
  padding-top: env(safe-area-inset-top) !important;
}

.pb-esaib {
  padding-top: env(safe-area-inset-bottom) !important;
}

.at-esait {
  top: env(safe-area-inset-top) !important;
}

.ChatInput--mobile {
  height: calc(70px + env(safe-area-inset-bottom)) !important;
}
